import * as React from 'react'
import PropTypes from 'prop-types'
import useInViewPort from '../hooks/useInViewPort'

const YouTube = ({ src, title, className, placeholder = <></> }) => {
    const [nodeRef, isVisible] = useInViewPort()
    return (
        <div ref={nodeRef} className={className}>
            {isVisible ?
                <iframe title={title} src={src} width="100%" height="100%" className={'border-0'} allowFullScreen="true"
                        loading="lazy"/> :
                placeholder}
        </div>
    )
}

YouTube.propTypes = {
    src: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.node
}

YouTube.defaultProps = {
    src: '',
    title: '',
    className: '',
    placeholder: <></>
}

export default YouTube

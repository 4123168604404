import { useEffect, useState, useRef } from 'react'

const THRESHOLD = 0

export default function useInViewPort() {
    let nodeRef = useRef(null)
    let observerRef = useRef(null)

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                setIsVisible(entries[0].isIntersecting)
            },
            { threshold: THRESHOLD }
        )

        observerRef.current.observe(nodeRef.current)

        return () => {
            observerRef.current.disconnect()
        }
    }, [])

    useEffect(() => {
        if (isVisible) {
            observerRef.current.disconnect()
        }
    }, [isVisible])

    return [nodeRef, isVisible]
}
